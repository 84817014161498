import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase_config"; // Import your Firestore config

// Async Thunk to fetch users from Firestore
export const getUsers = createAsyncThunk("customer/getUsers", async () => {
  try {
    console.log("Fetching users from Firestore...");
    // Reference to the users collection
    const querySnapshot = await getDocs(collection(db, "Fruitsy", "users", "userId"));

    // Create an array to store user data
    const users = [];
    querySnapshot.forEach((doc) => {
      console.log("Document data: ", doc.data()); // Log document data to verify
      users.push({
        id: doc.id, // Document ID
        ...doc.data(), // All document fields
      });
    });

    console.log("Fetched users: ", users); // Log the final users array
    return users;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
});
const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        console.log("Fetching users..."); // Log when fetching starts
        state.status = "loading";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        console.log("Fetched users successfully!"); // Log when users are fetched
        console.log("Payload: ", action.payload); // Log the action payload
        state.status = "succeeded";
        state.customers = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        console.log("Failed to fetch users"); // Log when fetching fails
        console.error("Error: ", action.error.message); // Log the error message
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default customerSlice.reducer;

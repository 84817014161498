import React, { useEffect } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createCoupon,
  getAllCoupons,
  resetState,
  updateCoupon,
} from "../features/coupon/couponSlice";

// Validation schema
const schema = yup.object().shape({
  name: yup.string().required("Coupon Name is Required"),
  expiry: yup.date().required("Expiry Date is Required"),
  discount: yup
    .number()
    .min(1, "Discount must be at least 1%")
    .max(100, "Discount cannot exceed 100%")
    .required("Discount Percentage is Required"),
});

const AddCoupon = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Extract coupon ID from the URL
  const couponId = location.pathname.split("/")[3];

  const {
    isSuccess,
    isError,
    createdCoupon,
    updatedCoupon,
    couponDetails,
  } = useSelector((state) => state.coupon);

  // Convert Firestore date format to YYYY-MM-DD for formik
  const formatToISODate = (date) =>
    date ? new Date(date).toISOString().split("T")[0] : "";

  useEffect(() => {
    if (couponId) {
      dispatch(getAllCoupons(couponId));
    } else {
      dispatch(resetState());
    }
  }, [couponId, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      if (createdCoupon) {
        toast.success("Coupon Added Successfully!");
        navigate("/admin/coupon-list");
      } else if (updatedCoupon) {
        toast.success("Coupon Updated Successfully!");
        navigate("/admin/coupon-list");
      }
    }

    if (isError) {
      toast.error("Something went wrong. Please try again!");
    }
  }, [isSuccess, isError, createdCoupon, updatedCoupon, navigate]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: couponDetails?.name || "",
      expiry: formatToISODate(couponDetails?.expiry),
      discount: couponDetails?.discount || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (couponId) {
        // Update existing coupon
        const data = { id: couponId, couponData: values };
        dispatch(updateCoupon(data));
      } else {
        // Create new coupon
        dispatch(createCoupon(values));
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">
        {couponId ? "Edit" : "Add"} Coupon
      </h3>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            name="name"
            onChng={formik.handleChange("name")}
            onBlr={formik.handleBlur("name")}
            val={formik.values.name}
            label="Enter Coupon Name"
            id="name"
          />
          <div className="error">
            {formik.touched.name && formik.errors.name}
          </div>

          <CustomInput
            type="date"
            name="expiry"
            onChng={formik.handleChange("expiry")}
            onBlr={formik.handleBlur("expiry")}
            val={formik.values.expiry}
            label="Enter Expiry Date"
            id="expiry"
          />
          <div className="error">
            {formik.touched.expiry && formik.errors.expiry}
          </div>

          <CustomInput
            type="number"
            name="discount"
            onChng={formik.handleChange("discount")}
            onBlr={formik.handleBlur("discount")}
            val={formik.values.discount}
            label="Enter Discount Percentage"
            id="discount"
          />
          <div className="error">
            {formik.touched.discount && formik.errors.discount}
          </div>

          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            {couponId ? "Update" : "Add"} Coupon
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddCoupon;

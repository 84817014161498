import React, { useEffect, useState } from "react";
import { Table, Modal, Form, Input } from "antd"; // Added Modal and Form
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { doc, deleteDoc, updateDoc } from "firebase/firestore"; // Firestore functions
import { db } from "../firebase/firebase_config"; // Your Firebase configuration file
import {
  deleteABlogCat,
  getCategories,
  resetState,
} from "../features/bcategory/bcategorySlice";
import CustomModal from "../components/CustomModal";

const Blogcatlist = () => {
  const [open, setOpen] = useState(false); // For delete confirmation modal
  const [blogCatId, setblogCatId] = useState(""); // Selected blog category ID for delete
  const [editModalOpen, setEditModalOpen] = useState(false); // For edit modal
  const [selectedBlogCat, setSelectedBlogCat] = useState(null); // Holds the selected blog category for editing
  const [editForm] = Form.useForm(); // Ant Design form instance

  const dispatch = useDispatch();

  // Show the delete confirmation modal
  const showDeleteModal = (id) => {
    setOpen(true);
    setblogCatId(id);
  };

  // Hide the delete confirmation modal
  const hideDeleteModal = () => {
    setOpen(false);
    setblogCatId("");
  };

  // Show the edit modal
  const showEditModal = (category) => {
    setSelectedBlogCat(category); // Set the selected blog category
    editForm.setFieldsValue({ name: category.title }); // Populate form with current values
    setEditModalOpen(true);
  };

  // Hide the edit modal
  const hideEditModal = () => {
    setEditModalOpen(false);
    setSelectedBlogCat(null);
  };

  // Fetch categories on component mount
  useEffect(() => {
    dispatch(resetState());
    dispatch(getCategories());
  }, [dispatch]);

  // Delete Blog Category
  const deleteBlogCategory = async (id) => {
    try {
      const blogDocRef = doc(db, "Fruitsy", "blog", "blogCategory", id); // Correct Firestore path
      await deleteDoc(blogDocRef);
      setOpen(false); // Close the modal
      dispatch(getCategories()); // Refresh categories
    } catch (error) {
      console.error("Error deleting blog category:", error.message);
    }
  };

  // Edit Blog Category
  const editBlogCategory = async (values) => {
    try {
      const blogDocRef = doc(db, "Fruitsy", "blog", "blogCategory", selectedBlogCat.id); // Correct Firestore path
      await updateDoc(blogDocRef, {
        title: values.name, // Update the document with new data
      });
      setEditModalOpen(false); // Close the modal
      dispatch(getCategories()); // Refresh categories
    } catch (error) {
      console.error("Error editing blog category:", error.message);
    }
  };

  // Map blog categories for the table
  const bCatState = useSelector((state) => state.bCategory.bCategories);
  const data1 = bCatState.map((category, index) => ({
    key: index + 1,
    name: category.title,
    action: (
      <>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showEditModal(category)}
        >
          <BiEdit />
        </button>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showDeleteModal(category.id)} // Use `id` instead of `_id`
        >
          <AiFillDelete />
        </button>
      </>
    ),
  }));

  return (
    <div>
      <h3 className="mb-4 title">Blog Categories</h3>
      <div>
        <Table
          columns={[
            { title: "SNo", dataIndex: "key" },
            { title: "Name", dataIndex: "name", sorter: (a, b) => a.name.length - b.name.length },
            { title: "Action", dataIndex: "action" },
          ]}
          dataSource={data1}
        />
      </div>

      {/* Delete Modal */}
      <CustomModal
        hideModal={hideDeleteModal}
        open={open}
        performAction={() => deleteBlogCategory(blogCatId)}
        title="Are you sure you want to delete this blog category?"
      />

      {/* Edit Modal */}
      <Modal
        title="Edit Blog Category"
        visible={editModalOpen}
        onCancel={hideEditModal}
        onOk={() => {
          editForm
            .validateFields()
            .then((values) => {
              editBlogCategory(values); // Submit edited values
              editForm.resetFields(); // Clear the form
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form form={editForm} layout="vertical" name="edit_blog_category_form">
          <Form.Item
            name="name"
            label="Category Name"
            rules={[{ required: true, message: "Please input the category name!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Blogcatlist;

import { db } from "../../firebase/firebase_config";
import { doc, addDoc, getDoc, updateDoc, collection } from "firebase/firestore";

// Add Product
const addProduct = async (productData) => {
  try {
    const newProductRef = await addDoc(collection(db, "Fruitsy", "products", "productid"), {
      ...productData,
      images: productData.image || "", // Ensure image URL is stored correctly
    });
    return { id: newProductRef.id, ...productData };
  } catch (error) {
    throw new Error(error.message);
  }
};

// Update Product
const updateProduct = async (id, productData) => {
  try {
    const productRef = doc(db, "Fruitsy", "products", "productid", id);
    await updateDoc(productRef, {
      ...productData,
      images: productData.image || "", // Ensure image URL is stored correctly
    });
    return { id, ...productData };
  } catch (error) {
    throw new Error(error.message);
  }
};

// Fetch Product by ID
const fetchProductById = async (id) => {
  try {
    const productRef = doc(db, "Fruitsy", "products", "productid", id);
    const docSnap = await getDoc(productRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return {
        id,
        ...data,
        images: data.image || "", // Ensure image field exists
      };
    }
    throw new Error("Product not found");
  } catch (error) {
    throw new Error(error.message);
  }
};

const productService = {
  addProduct,
  updateProduct,
  fetchProductById,
};

export default productService;

// enquirySlice.js
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import enquiryService from "./enquiryService";

// Fetch all enquiries
export const getEnquiries = createAsyncThunk(
  "enquiry/get-enquiries",
  async (_, thunkAPI) => {
    try {
      return await enquiryService.getEnquiries();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch a single enquiry by ID
export const getEnquiry = createAsyncThunk(
  "enquiry/get-enquiry",
  async (id, thunkAPI) => {
    try {
      return await enquiryService.getEnquiry(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Delete an enquiry by ID
export const deleteAEnquiry = createAsyncThunk(
  "enquiry/delete-enquiry",
  async (id, thunkAPI) => {
    try {
      return await enquiryService.deleteEnquiry(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Update an enquiry
export const updateAEnquiry = createAsyncThunk(
  "enquiry/update-enquiry",
  async (enquiry, thunkAPI) => {
    try {
      return await enquiryService.updateEnquiry(enquiry);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const ViewEnq = createAsyncThunk(
  "enquiry/view-enquiry",
  async (enquiry, thunkAPI) => {
    try {
      return await enquiryService.ViewEnq(enquiry);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Reset state
export const resetState = createAction("Reset_all");

// Initial state
const initialState = {
  enquiries: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

// Slice definition
const enquirySlice = createSlice({
  name: "enquiries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEnquiries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEnquiries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.enquiries = action.payload;
      })
      .addCase(getEnquiries.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getEnquiry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEnquiry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        const { name, mobile, email, comment, status } = action.payload;
        state.enqName = name;
        state.enqMobile = mobile;
        state.enqEmail = email;
        state.enqComment = comment;
        state.enqStatus = status;
      })
      .addCase(getEnquiry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteAEnquiry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAEnquiry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.enquiries = state.enquiries.filter(
          (enquiry) => enquiry.id !== action.payload.id
        );
      })
      .addCase(deleteAEnquiry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateAEnquiry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAEnquiry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.enquiries = state.enquiries.map((enquiry) =>
          enquiry.id === action.payload.id ? action.payload : enquiry
        );
      })
      .addCase(updateAEnquiry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(resetState, () => initialState);
  },
});

export default enquirySlice.reducer;

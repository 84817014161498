
import { db } from "../../firebase/firebase_config";
 // Ensure you have Firebase configured in this file
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

// Correctly initialize the collection reference
const bCategoryCollectionRef = collection(db, "Fruitsy", "blog", "blogCategory");

const bCategoryService = {
  // Fetch all blog categories
  getBlogCategories: async () => {
    const querySnapshot = await getDocs(bCategoryCollectionRef);
    const categories = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return categories;
  },

  // Create a new blog category
  createBlogCategory: async (catData) => {
    const newCategory = await addDoc(bCategoryCollectionRef, catData);
    return { id: newCategory.id, ...catData };
  },

  // Get a single blog category by ID
  getBlogCategory: async (id) => {
    const docRef = doc(db, "Fruitsy", "blog", "blogCategory", id);
    const categoryDoc = await getDoc(docRef);
    if (categoryDoc.exists()) {
      return { id: categoryDoc.id, ...categoryDoc.data() };
    } else {
      throw new Error("Blog category not found");
    }
  },

  // Update a blog category
  updateBlogCategory: async ({ id, ...updates }) => {
    const docRef = doc(db, "Fruitsy", "blog", "blogCategory", id);
    await updateDoc(docRef, updates);
    return { id, ...updates };
  },

  // Delete a blog category
  deleteBlogCategory: async (id) => {
    const docRef = doc(db, "Fruitsy", "blog", "blogCategory", id);
    await deleteDoc(docRef);
    return id;
  },
};

export default bCategoryService;

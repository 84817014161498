import React, { useEffect, useState } from "react";
import { Table, Modal, Form, Input } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs, resetState, deleteABlog, updateABlog } from "../features/blogs/blogSlice";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase_config";
import CustomModal from "../components/CustomModal";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Title",
    dataIndex: "name",
  },
  {
    title: "Category",
    dataIndex: "category",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const BlogList = () => {
  const [open, setOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState("");
  const [editForm] = Form.useForm();
  const dispatch = useDispatch();
  const blogState = useSelector((state) => state.blogs.blogs);

  useEffect(() => {
    dispatch(resetState());
    dispatch(getBlogs());
  }, [dispatch]);

  // Delete Blog
  const deleteBlog = async (id) => {
    try {
      const blogDocRef = doc(db, "Fruitsy", "blog", "blogs", id);
      await deleteDoc(blogDocRef);
      setOpen(false);
      dispatch(deleteABlog(id));
      dispatch(getBlogs()); // Refresh the blog list
    } catch (error) {
      console.error("Error deleting blog:", error.message);
    }
  };

  // Edit Blog
  const editBlog = async (values) => {
    try {
      const blogDocRef = doc(db, "Fruitsy", "blog", "blogs", selectedBlogId);
      await updateDoc(blogDocRef, {
        title: values.name,
        category: values.category,
        description:values.description,
      });
      setEditModalOpen(false);
      dispatch(updateABlog({ id: selectedBlogId, ...values }));
      dispatch(getBlogs()); // Refresh the blog list
    } catch (error) {
      console.error("Error editing blog:", error.message);
    }
  };

  const data = blogState.map((blog, index) => ({
    key: index + 1,
    name: blog.title,
    category: blog.category,
    action: (
      <>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showEditModal(blog)}
        >
          <BiEdit />
        </button>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showDeleteModal(blog.id)}
        >
          <AiFillDelete />
        </button>
      </>
    ),
  }));

  const showDeleteModal = (id) => {
    setOpen(true);
    setSelectedBlogId(id);
  };

  const hideDeleteModal = () => {
    setOpen(false);
    setSelectedBlogId("");
  };

  const showEditModal = (blog) => {
    setSelectedBlogId(blog.id);
    editForm.setFieldsValue({
      name: blog.title,
      category: blog.category,
      description:blog.description,
    });
    setEditModalOpen(true);
  };

  const hideEditModal = () => {
    setEditModalOpen(false);
    setSelectedBlogId("");
  };

  return (
    <div>
      <h3 className="mb-4 title">Blogs List</h3>
      <div>
        <Table columns={columns} dataSource={data} />
      </div>

      {/* Delete Modal */}
      <CustomModal
        hideModal={hideDeleteModal}
        open={open}
        performAction={() => deleteBlog(selectedBlogId)}
        title="Are you sure you want to delete this blog?"
      />

      {/* Edit Modal */}
      <Modal
        title="Edit Blog"
        visible={editModalOpen}
        onCancel={hideEditModal}
        onOk={() => {
          editForm
            .validateFields()
            .then((values) => {
              editBlog(values);
              editForm.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form form={editForm} layout="vertical" name="edit_blog_form">
          <Form.Item
            name="name"
            label="Title"
            rules={[{ required: true, message: "Please input the blog title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: "Please input the blog category!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="description"
            rules={[{ required: true, message: "Please input the description!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default BlogList;

import React, { useState, useEffect } from "react";
import { Modal, Form, Input, InputNumber, Button } from "antd";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase_config"; // Firestore config

const EditProductModal = ({ open, hideModal, productId, fetchProducts }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (productId) {
        try {
          const productDoc = await getDoc(doc(db, "Fruitsy", "products", "productid", productId));
          if (productDoc.exists()) {
            form.setFieldsValue(productDoc.data());
          }
        } catch (error) {
          console.error("Error fetching product details:", error.message);
        }
      }
    };

    fetchProductDetails();
  }, [productId, form]);

  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      const productRef = doc(db, "Fruitsy", "products", "productid", productId);
      await updateDoc(productRef, values);
      setLoading(false);
      hideModal();
      fetchProducts(); // Refresh the product list
    } catch (error) {
      console.error("Error updating product:", error.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Edit Product"
      visible={open}
      onCancel={hideModal}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleUpdate}>
        <Form.Item
          name="name"
          label="Product Name"
          rules={[{ required: true, message: "Please enter the product name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="stock"
          label="Quantity"
          rules={[{ required: true, message: "Please enter the quantity" }]}
        >
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="price"
          label="Price"
          rules={[{ required: true, message: "Please enter the price" }]}
        >
          <InputNumber min={0.01} step={0.01} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditProductModal;

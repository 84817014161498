import React, { useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import Dropzone from "react-dropzone";
import productService from "../features/product/productService";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase/firebase_config"; // Ensure Firebase is initialized here
import { doc, setDoc } from "firebase/firestore";

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  price: yup.number().required("Price is required"),
  tags: yup.string().required("Tag is required"),
  quantity: yup.number().required("Quantity is required"),
});

const AddProduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const getProductId = location.pathname.split("/")[3];

  const [product, setProduct] = useState(null);
  const [imageUrl, setImageUrl] = useState(""); // Store image URL as a string

  useEffect(() => {
    if (getProductId) {
      // Fetch product details and set state
      productService
        .fetchProductById(getProductId)
        .then((data) => {
          setProduct(data);
          setImageUrl(data.image || ""); // Load existing image URL
        })
        .catch(() => toast.error("Failed to fetch product details"));
    }
  }, [getProductId]);

  const handleImageUpload = async (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      toast.error("No files selected");
      return;
    }

    const storage = getStorage(); // Initialize Firebase Storage
    const file = acceptedFiles[0]; // Take the first file
    const uniqueName = `${Date.now()}_${file.name}`; // Use unique identifier for the file name
    const storageRef = ref(storage, `images/${uniqueName}`); // Define storage path

    try {
      // Upload file to Firebase Storage
      await uploadBytes(storageRef, file);

      // Get the public URL of the image
      const url = await getDownloadURL(storageRef);
      setImageUrl(url); // Set the publicly accessible URL to state

      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Image upload error:", error); // Log any errors
      toast.error("Failed to upload image");
    }
  };

  const formik = useFormik({
    initialValues: {
      title: product?.name || "",
      description: product?.description || "",
      price: product?.price || "",
      tags: product?.tags || "",
      quantity: product?.stock || "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (!imageUrl) {
        toast.error("Please upload an image before submitting.");
        return;
      }

      const transformedValues = {
        ...values,
        name: values.title, // Map title to name
        stock: values.quantity, // Map quantity to stock
        images: imageUrl, // Save image URL as a string
      };

      delete transformedValues.title; // Remove title
      delete transformedValues.quantity; // Remove quantity

      try {
        const productDocRef = doc(
          db,
          "Fruitsy",
          "products",
          "productid",
          getProductId || Date.now().toString()
        );
        await setDoc(productDocRef, transformedValues, { merge: true });
        toast.success(
          getProductId ? "Product updated successfully" : "Product added successfully"
        );
        navigate("/admin/list-product");
      } catch (error) {
        console.error("Form submission error:", error); // Log any errors
        toast.error("Something went wrong");
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">{getProductId ? "Edit" : "Add"} Product</h3>
      <form onSubmit={formik.handleSubmit} className="d-flex gap-3 flex-column">
        <CustomInput
          type="text"
          label="Enter Product Title"
          name="title"
          val={formik.values.title}
          onChng={formik.handleChange("title")}
          onBlr={formik.handleBlur("title")}
        />
        <div className="error">{formik.touched.title && formik.errors.title}</div>

        <ReactQuill
          theme="snow"
          value={formik.values.description}
          onChange={(value) => formik.setFieldValue("description", value)}
        />
        <div className="error">{formik.touched.description && formik.errors.description}</div>

        <CustomInput
          type="number"
          label="Enter Product Price"
          name="price"
          val={formik.values.price}
          onChng={formik.handleChange("price")}
          onBlr={formik.handleBlur("price")}
        />
        <div className="error">{formik.touched.price && formik.errors.price}</div>

        <CustomInput
          type="number"
          label="Enter Product Quantity"
          name="quantity"
          val={formik.values.quantity}
          onChng={formik.handleChange("quantity")}
          onBlr={formik.handleBlur("quantity")}
        />
        <div className="error">{formik.touched.quantity && formik.errors.quantity}</div>

        <CustomInput
          type="text"
          label="Enter Product Tags"
          name="tags"
          val={formik.values.tags}
          onChng={formik.handleChange("tags")}
          onBlr={formik.handleBlur("tags")}
        />
        <div className="error">{formik.touched.tags && formik.errors.tags}</div>

        <div className="bg-white border-1 p-5 text-center">
          <Dropzone onDrop={handleImageUpload}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            )}
          </Dropzone>
        </div>

        {imageUrl && (
          <div className="showimages">
            <div className="position-relative">
              <button
                type="button"
                className="btn-close position-absolute"
                onClick={() => setImageUrl("")}
              ></button>
              <img src={imageUrl} alt="product" width={200} height={200} />
            </div>
          </div>
        )}

        <button type="submit" className="btn btn-success border-0 rounded-3 my-5">
          {getProductId ? "Update" : "Add"} Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;

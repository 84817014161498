import { db } from "../../firebase/firebase_config"; // Import the initialized Firebase instance
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
} from "firebase/firestore";

// User Login Function
const login = async (user) => {
  const userRef = collection(db, "users");
  const q = query(
    userRef,
    where("email", "==", user.email),
    where("password", "==", user.password)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const userData = querySnapshot.docs[0].data();
    localStorage.setItem("user", JSON.stringify(userData));
    return userData;
  } else {
    throw new Error("Invalid credentials");
  }
};

// Fetch All Orders
const getOrders = async () => {
  const ordersRef = collection(db, "Fruitsy", "orders","orderId");
  const querySnapshot = await getDocs(ordersRef);

  if (!querySnapshot.empty) {
    const orders = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return orders;
  } else {
    console.warn("No orders found.");
    return [];
  }
};

// Fetch a Specific Order by ID
const getOrder = async (id) => {
  const orderRef = doc(db, "Fruitsy", "orders","orderId", id); // Correct path
  const orderSnapshot = await getDoc(orderRef);

  if (orderSnapshot.exists()) {
    return { id: orderSnapshot.id, ...orderSnapshot.data() };
  } else {
    throw new Error("Order not found");
  }
};

// Update Order Status
const updateOrder = async (data) => {
  const orderRef = doc(db, "Fruitsy", "orders", data.id); // Correct path
  await updateDoc(orderRef, { status: data.status });
  return { id: data.id, status: data.status };
};

// Fetch Monthly Orders
const getMonthlyOrders = async (month, year) => {
  const ordersRef = collection(db, "Fruitsy", "orders");
  const q = query(
    ordersRef,
    where("month", "==", month),
    where("year", "==", year)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const monthlyOrders = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return monthlyOrders;
  } else {
    console.warn("No monthly orders found.");
    return [];
  }
};

// Fetch Yearly Stats
const getYearlyStats = async (year) => {
  const ordersRef = collection(db, "Fruitsy", "orders");
  const q = query(ordersRef, where("year", "==", year));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const yearlyStats = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return yearlyStats;
  } else {
    console.warn("No yearly stats found.");
    return [];
  }
};

// AuthService Object
const authService = {
  login,
  getOrders,
  getOrder,
  getMonthlyOrders,
  getYearlyStats,
  updateOrder,
};

export default authService;

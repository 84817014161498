import React, { useEffect, useState } from "react";
import { Table, message, Modal } from "antd";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase_config"; // Ensure this is your Firebase config

const columns = [
  { title: "SNo", dataIndex: "key", key: "key" },
  { title: "User Name", dataIndex: "userEmail", key: "userEmail" },
  { title: "Product", dataIndex: "productname", key: "productname" },
  { title: "Total Amount", dataIndex: "totalAmount", key: "totalAmount" },
  { title: "Payment Status", dataIndex: "paymentStatus", key: "paymentStatus" },
  { title: "Order Status", dataIndex: "status", key: "status" },
  { title: "Shipping Address", dataIndex: "shippingAddress", key: "shippingAddress" },
  { title: "Date", dataIndex: "createdAt", key: "createdAt" },
];

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null); // To store selected order details
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const ordersCollection = collection(db, "Fruitsy", "orders", "orderId");
        const snapshot = await getDocs(ordersCollection);
  
        const fetchedOrders = snapshot.docs.map((doc, index) => ({
          id: doc.id, // Document ID
          key: index + 1,
          userEmail: doc.data().userEmail || "N/A",
          productname: doc.data().cartItems[0]?.title || "N/A",
          totalAmount: `${parseFloat(doc.data().cartItems[0]?.price || 0).toFixed(2)}`,
          paymentStatus: doc.data().paymentStatus || "Pending",
          status: doc.data().status || "Pending",
          shippingAddress: doc.data().shippingInfo.address || "N/A",
          createdAt: new Date(doc.data().date).toLocaleString(), // Display format
          timestamp: new Date(doc.data().date), // For sorting
        }));
  
        // Sort orders by date (most recent first)
        const sortedOrders = fetchedOrders.sort((a, b) => b.timestamp - a.timestamp);
  
        setOrders(sortedOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
        message.error("Failed to fetch orders.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchOrders();
  }, []);
  

  const handleStatusUpdate = async (orderId, status) => {
    try {
      const orderDoc = doc(db, "Fruitsy", "orders", "orderId", orderId);
      await updateDoc(orderDoc, { status });
      message.success(`Order status updated to "${status}"`);
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, status } : order
        )
      );
    } catch (error) {
      console.error("Error updating order status:", error);
      message.error("Failed to update order status.");
    }
  };

  const handleRowClick = (record) => {
    // Fetch detailed order data and show in a modal
    const selected = orders.find((order) => order.id === record.id);
    setSelectedOrder(selected);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedOrder(null);
  };

  return (
    <div>
      <h3 className="mb-4">Orders</h3>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />

      <Modal
        title="Order Details"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedOrder && (
          <div>
            <p><strong>Order ID:</strong> {selectedOrder.id}</p>
            <p><strong>User Name:</strong> {selectedOrder.userEmail}</p>
            <p><strong>Shipping Address:</strong> {selectedOrder.shippingAddress}</p>
            <p><strong>Product Name:</strong> {selectedOrder.productname}</p>
            <p><strong>Total Amount:</strong> {selectedOrder.totalAmount}</p>
            <p><strong>Status:</strong> {selectedOrder.status}</p>
            <p><strong>Order Date:</strong> {selectedOrder.createdAt}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Orders;

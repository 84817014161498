// enquiryService.js
import { db } from "../../firebase/firebase_config"; // Adjust the path to your firebase.js
import { collection, doc, getDocs, getDoc, deleteDoc, updateDoc } from "firebase/firestore";

// Fetch all enquiries
const getEnquiries = async () => {
  const enquiryCollection = collection(db,"Fruitsy", "enquries","enquriesId");
  const snapshot = await getDocs(enquiryCollection);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

// Fetch a single enquiry by ID
const getEnquiry = async (id) => {
  const enquiryDoc = doc(db,"Fruitsy", "enquries","enquriesId", id);
  const snapshot = await getDoc(enquiryDoc);
  if (snapshot.exists()) {
    return { id: snapshot.id, ...snapshot.data() };
  } else {
    throw new Error("Enquiry not found");
  }
};
const ViewEnq = async (id) => {
  const enquiryDoc = doc(db,"Fruitsy", "enquries","enquriesId", id);
  const snapshot = await getDoc(enquiryDoc);
  if (snapshot.exists()) {
    return { id: snapshot.id, ...snapshot.data() };
  } else {
    throw new Error("Enquiry not found");
  }
};
// Delete an enquiry by ID
const deleteEnquiry = async (id) => {
  const enquiryDoc = doc(db,"Fruitsy", "enquries","enquriesId", id);
  await deleteDoc(enquiryDoc);
  return { id }; // Return deleted enquiry's ID
};

// Update an enquiry
const updateEnquiry = async (enquiry) => {
  const { id, ...fieldsToUpdate } = enquiry;
  const enquiryDoc = doc(db,"Fruitsy", "enquries","enquriesId", id);
  await updateDoc(enquiryDoc, fieldsToUpdate);
  return { id, ...fieldsToUpdate };
};

// Export all services
const enquiryService = {
  getEnquiries,
  getEnquiry,
  deleteEnquiry,
  updateEnquiry,
  ViewEnq
};

export default enquiryService;

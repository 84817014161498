import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { resetState } from "../features/product/productSlice";
import CustomModal from "../components/CustomModal";
import EditProductModal from "../pages/EditProduct"; // Import the new modal
import { db } from "../firebase/firebase_config"; // Firestore config
import { doc, deleteDoc, collection, getDocs } from "firebase/firestore";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Title",
    dataIndex: "name",
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: "Quantity",
    dataIndex: "stock",
  },
  {
    title: "Price",
    dataIndex: "price",
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const Productlist = () => {
  const [open, setOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [productId, setProductId] = useState("");
  const [productData, setProductData] = useState([]);
  const dispatch = useDispatch();

  const showModal = (id) => {
    setOpen(true);
    setProductId(id);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const showEditModal = (id) => {
    setEditModalOpen(true);
    setProductId(id);
  };

  const hideEditModal = () => {
    setEditModalOpen(false);
  };

  const fetchProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Fruitsy", "products", "productid"));
      const products = querySnapshot.docs.map((doc, index) => ({
        key: index + 1,
        id: doc.id,
        ...doc.data(),
        action: (
          <>
            <button
              className="fs-3 text-success bg-transparent border-0"
              onClick={() => showEditModal(doc.id)}
            >
              <BiEdit />
            </button>
            <button
              className="ms-3 fs-3 text-danger bg-transparent border-0"
              onClick={() => showModal(doc.id)}
            >
              <AiFillDelete />
            </button>
          </>
        ),
      }));
      setProductData(products);
    } catch (error) {
      console.error("Error fetching products:", error.message);
    }
  };

  useEffect(() => {
    fetchProducts();
    dispatch(resetState());
  }, [dispatch]);

  const deleteProduct = async (id) => {
    try {
      await deleteDoc(doc(db, "Fruitsy", "products", "productid", id));
      setOpen(false);
      fetchProducts();
    } catch (error) {
      console.error("Error deleting product:", error.message);
    }
  };

  return (
    <div>
      <h3 className="mb-4 title">Products</h3>
      <div>
        <Table columns={columns} dataSource={productData} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => deleteProduct(productId)}
        title="Are you sure you want to delete this Product?"
      />
      <EditProductModal
        open={editModalOpen}
        hideModal={hideEditModal}
        productId={productId}
        fetchProducts={fetchProducts}
      />
    </div>
  );
};

export default Productlist;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

// Thunk to upload images to Firebase Storage
export const uploadImg = createAsyncThunk(
  "upload/uploadImg",
  async (files, thunkAPI) => {
    try {
      const storage = getStorage();
      const uploadedImages = [];

      for (const file of files) {
        const storageRef = ref(storage, `images/${file.name}-${Date.now()}`);
        const uploadTask = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(uploadTask.ref);

        uploadedImages.push({
          public_id: storageRef.fullPath, // Store the full path for deleting
          url: url, // Store the download URL for displaying
        });
      }
      return uploadedImages;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Thunk to delete an image from Firebase Storage
export const delImg = createAsyncThunk(
  "upload/delImg",
  async (public_id, thunkAPI) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, public_id);
      await deleteObject(imageRef); // Delete the image from Firebase Storage
      return public_id; // Return the public_id to filter out the deleted image
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    images: [], // Store uploaded images' data (public_id and url)
    isLoading: false, // Flag for loading state
    error: null, // Error state for image upload or deletion
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle upload image actions
      .addCase(uploadImg.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadImg.fulfilled, (state, action) => {
        state.isLoading = false;
        state.images = action.payload; // Store the uploaded images
      })
      .addCase(uploadImg.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload; // Store the error if any occurs
      })
      // Handle delete image action
      .addCase(delImg.fulfilled, (state, action) => {
        state.images = state.images.filter(
          (img) => img.public_id !== action.payload // Filter out the deleted image by public_id
        );
      });
  },
});

export default uploadSlice.reducer;

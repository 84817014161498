import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../features/cutomers/customerSlice";

const columns = [
  { title: "SNo", dataIndex: "key" },
  { title: "Name", dataIndex: "name", sorter: (a, b) => a.name.length - b.name.length },
  { title: "Email", dataIndex: "email" },
  { title: "Mobile", dataIndex: "mobile" },
  
  { title: "Address", dataIndex: "address" },
];

const Customers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers()); // Fetch users when component mounts
  }, [dispatch]);

  const customerState = useSelector((state) => state.customer.customers); // Access state
  console.log("Customers data from Redux state: ", customerState); // Log the customers data

  const data = customerState.map((user, index) => ({
    key: index + 1,
    name: `${user.firstname} ${user.lastname}`,
    email: user.email,
    mobile: user.mobile,
    address:user.address
  }));

  console.log("Mapped data for table: ", data); // Log the data for the table

  return (
    <div>
      <h3 className="mb-4 title">Customers</h3>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default Customers;

import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { Table } from "antd";
import { getFirestore, collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/firebase_config';
import { useSelector } from "react-redux";

const columns = [
  { title: "User Name", dataIndex: "userEmail", key: "userEmail" },
  { title: "Product", dataIndex: "productname", key: "productname" },
  { title: "Total Amount", dataIndex: "totalAmount", key: "totalAmount" },
  { title: "Payment Status", dataIndex: "paymentStatus", key: "paymentStatus" },
  { title: "Order Status", dataIndex: "status", key: "status" },
  { title: "Shipping Address", dataIndex: "shippingAddress", key: "shippingAddress" },
  { title: "Date", dataIndex: "createdAt", key: "createdAt" },
];

const Dashboard = () => {
  const [dataMonthly, setDataMonthly] = useState([]);
  const [dataMonthlySales, setDataMonthlySales] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [yearlyDataState, setYearlyDataState] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0); // State to store total income

  const user = useSelector(state => state.auth.user);  // Assume user data is in the Redux state

  const fetchMonthlyData = async () => {
    const monthlyRef = collection(db, "Fruitsy", "monthlyData","monthlyDataId"); // Reference the collection
    const monthlySnapshot = await getDocs(monthlyRef); // Fetch all documents in the collection
    const monthlyData = [];
    const monthlySalesData = [];
    let totalIncomeForYear = 0; // Variable to calculate total income for the year
  
    monthlySnapshot.forEach((doc) => {
      const data = doc.data();
      monthlyData.push({
        type: data.monthName, // Assuming Firestore stores monthName
        income: data.amount, // Assuming 'amount' is the income field in Firestore
      });
      monthlySalesData.push({
        type: data.monthName,
        income: data.count, // Assuming 'count' is the sales field in Firestore
      });
  
      totalIncomeForYear += data.amount; // Summing up the income for the year
    });
  
    setDataMonthly(monthlyData);
    setDataMonthlySales(monthlySalesData);
    setTotalIncome(totalIncomeForYear); // Set the total income
  };
  

  const fetchYearlyData = async () => {
    const yearlyRef = collection(db, "Fruitsy");
    const yearlySnapshot = await getDocs(yearlyRef);
    const yearlyData = [];
    yearlySnapshot.forEach((doc) => {
      const data = doc.data();
      yearlyData.push({
        amount: data.totalIncome,
        count: data.totalSales,
      });
    });
    setYearlyDataState(yearlyData);
  };

  const fetchOrders = async () => {
    const ordersRef = collection(db, "Fruitsy", "orders", "orderId");
    const q = query(ordersRef, orderBy("date")); // Sort by creation date
    const orderSnapshot = await getDocs(q);
    const orders = [];
    let orderCount = 0; // Variable to count the total number of orders
    let totalIncomeFromOrders = 0; // Variable to calculate total income from orders

    orderSnapshot.forEach((doc, index) => {
      const order = doc.data();
      const total = parseFloat(order.total);
      orders.push({
        key: index + 1,
        userEmail: doc.data().userEmail,
        productname: doc.data().cartItems[0]?.title || "N/A",
        totalAmount: `${parseFloat(doc.data().cartItems[0]?.price || 0).toFixed(2)}`,
        paymentStatus: doc.data().paymentStatus || "Pending",
        status: doc.data().status || "Pending",
        shippingAddress: doc.data().shippingInfo.address || "N/A",
        createdAt: new Date(doc.data().date).toLocaleString(),
      });
      
      orderCount++; // Increment order count
      totalIncomeFromOrders += total; // Add to the total income
    });

    setOrderData(orders);
    setTotalOrders(orderCount); // Set total orders count
    setTotalIncome(totalIncomeFromOrders); // Set total income from orders
  };

  useEffect(() => {
    fetchMonthlyData();
    fetchYearlyData();
    fetchOrders();
  }, []); // Run on mount
{/*
  const config = {
    data: dataMonthly,
    xField: "type",
    yField: "income",
    color: ({ type }) => "#ffd333",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month",
      },
      sales: {
        alias: "Income",
      },
    },
  };

  const config2 = {
    data: dataMonthlySales,
    xField: "type",
    yField: "income",
    color: ({ type }) => "#ffd333",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month",
      },
      sales: {
        alias: "Income",
      },
    },
  };
*/}
  return (
    <div>
      <h3 className="mb-4 title">Dashboard</h3>
      <div className="d-flex justify-content-between align-items-center gap-3">
        <div className="d-flex p-3 justify-content-between align-items-end flex-grow-1 bg-white rounded-3">
          <div>
            <p className="desc">Total Income</p>
            <h4 className="mb-0 sub-title">
              Rs.{totalIncome || "0.00"} {/* Display the total income */}
            </h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <p className="mb-0 desc">Income in Last Year</p>
          </div>
        </div>
        <div className="d-flex p-3 justify-content-between align-items-end flex-grow-1 bg-white rounded-3">
          <div>
            <p className="desc">Total Sales</p>
            <h4 className="mb-0 sub-title">
              {totalOrders || "0"} {/* Display the total number of orders */}
            </h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <p className="mb-0 desc">Sales in Last Year</p>
          </div>
        </div>
      </div>{/*
      <div className="d-flex justify-content-between align-items gap-3">
        <div className="mt-4 flex-grow-1 w-50">
          <h3 className="mb-5 title">Income</h3>
          <Column {...config} />
        </div>
        <div className="mt-4 flex-grow-1">
          <h3 className="mb-5 title">Sales</h3>
          <Column {...config2} />
        </div>
      </div>*/}
      <div className="mt-4">
        <h3 className="mb-5 title">Recent Orders</h3>
        <Table columns={columns} dataSource={orderData} />
      </div>
    </div>
  );
};

export default Dashboard;

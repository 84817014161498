import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, doc, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase_config"; // Import your Firestore config

// Fetch all coupons
export const getAllCoupons = createAsyncThunk("coupon/fetchCoupons", async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "Fruitsy", "coupons", "couponsId"));
    let coupons = [];
    querySnapshot.forEach((doc) => {
      coupons.push({ id: doc.id, ...doc.data() });
    });
    return coupons;
  } catch (error) {
    throw new Error(error.message);
  }
});

// Add a new coupon
export const createCoupon = createAsyncThunk(
  "coupon/createCoupon",
  async (couponData, { rejectWithValue }) => {
    try {
      const docRef = await addDoc(collection(db, "Fruitsy", "coupons", "couponsId"), couponData);
      return { id: docRef.id, ...couponData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Update an existing coupon
export const updateCoupon = createAsyncThunk(
  "coupon/updateCoupon",
  async ({ id, couponData }, { rejectWithValue }) => {
    try {
      const couponRef = doc(db, "Fruitsy", "coupons", "couponsId", id);
      await updateDoc(couponRef, couponData);
      return { id, ...couponData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    coupons: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    createdCoupon: null,
    updatedCoupon: null,
  },
  reducers: {
    resetState: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
      state.createdCoupon = null;
      state.updatedCoupon = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Coupons
      .addCase(getAllCoupons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coupons = action.payload;
      })
      .addCase(getAllCoupons.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      })

      // Create Coupon
      .addCase(createCoupon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createdCoupon = action.payload;
        state.coupons.push(action.payload);
      })
      .addCase(createCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })

      // Update Coupon
      .addCase(updateCoupon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updatedCoupon = action.payload;
        const index = state.coupons.findIndex((c) => c.id === action.payload.id);
        if (index !== -1) {
          state.coupons[index] = action.payload;
        }
      })
      .addCase(updateCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export const { resetState } = couponSlice.actions;
export default couponSlice.reducer;

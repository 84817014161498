import { db } from '../../firebase/firebase_config'; // Import your Firestore database instance
import { collection, addDoc, getDocs, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';

// Create Blog
const createBlog = async (blogData) => {
  try {
    const docRef = await addDoc(collection(db, "Fruitsy","blog","blogs"), blogData);
    return { id: docRef.id, ...blogData };
  } catch (error) {
    throw new Error(error.message);
  }
};

// Get All Blogs
const getBlogs = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "Fruitsy","blog","blogs"));
    const blogs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return blogs;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Get a Single Blog by ID
const getBlog = async (id) => {
  try {
    const docRef = doc(db,  "Fruitsy","blog","blogs", id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      throw new Error("Blog not found");
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

// Update Blog
const updateBlog = async (id, updatedData) => {
  try {
    const docRef = doc(db,  "Fruitsy","blog","blogs", id);
    await updateDoc(docRef, updatedData);
    return { id, ...updatedData };
  } catch (error) {
    throw new Error(error.message);
  }
};

// Delete Blog
const deleteBlog = async (id) => {
  try {
    const docRef = doc(db,  "Fruitsy","blog","blogs", id);
    await deleteDoc(docRef);
    return id;
  } catch (error) {
    throw new Error(error.message);
  }
};

export default { createBlog, getBlogs, getBlog, updateBlog, deleteBlog };

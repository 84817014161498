import React, { useEffect, useState } from "react";
import { Table, Modal, Form, Input } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons, resetState } from "../features/coupon/couponSlice";
import CustomModal from "../components/CustomModal";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase_config";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: "Discount",
    dataIndex: "discount",
    sorter: (a, b) => parseFloat(a.discount) - parseFloat(b.discount),
  },
  {
    title: "Expiry",
    dataIndex: "expiry",
    sorter: (a, b) => new Date(a.expiry) - new Date(b.expiry),
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const CouponList = () => {
  const [open, setOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [editForm] = Form.useForm();
  const dispatch = useDispatch();
  const couponState = useSelector((state) => state.coupon.coupons);

  useEffect(() => {
    dispatch(resetState());
    dispatch(getAllCoupons());
  }, [dispatch]);

  // Delete Coupon
  const deleteCoupon = async (id) => {
    try {
      const couponDocRef = doc(db, "Fruitsy", "coupons", "couponsId", id);
      await deleteDoc(couponDocRef);
      setOpen(false);
      dispatch(getAllCoupons());
    } catch (error) {
      console.error("Error deleting coupon:", error.message);
    }
  };

  // Edit Coupon
  const editCoupon = async (values) => {
    try {
      const couponDocRef = doc(db, "Fruitsy", "coupons", "couponsId", selectedCouponId);
      await updateDoc(couponDocRef, {
        name: values.name,
        discount: values.discount,
        expiry: values.expiry,
      });
      setEditModalOpen(false);
      dispatch(getAllCoupons());
    } catch (error) {
      console.error("Error editing coupon:", error.message);
    }
  };

  const data = couponState.map((coupon, index) => ({
    key: index + 1,
    name: coupon.name,
    discount: `${coupon.discount}%`,
    expiry: new Date(coupon.expiry).toLocaleDateString("en-US"),
    action: (
      <>
        <button
          className="fs-3 text-danger bg-transparent border-0"
          onClick={() => showEditModal(coupon)}
        >
          <BiEdit />
        </button>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showDeleteModal(coupon.id)}
        >
          <AiFillDelete />
        </button>
      </>
    ),
  }));

  const showDeleteModal = (id) => {
    setOpen(true);
    setSelectedCouponId(id);
  };

  const hideDeleteModal = () => {
    setOpen(false);
    setSelectedCouponId("");
  };

  const showEditModal = (coupon) => {
    setSelectedCouponId(coupon.id);
    editForm.setFieldsValue({
      name: coupon.name,
      discount: coupon.discount,
      expiry: coupon.expiry,
    });
    setEditModalOpen(true);
  };

  const hideEditModal = () => {
    setEditModalOpen(false);
    setSelectedCouponId("");
  };

  return (
    <div>
      <h3 className="mb-4 title">Coupons</h3>
      <div>
        <Table columns={columns} dataSource={data} />
      </div>

      {/* Delete Modal */}
      <CustomModal
        hideModal={hideDeleteModal}
        open={open}
        performAction={() => deleteCoupon(selectedCouponId)}
        title="Are you sure you want to delete this Coupon?"
      />

      {/* Edit Modal */}
      <Modal
        title="Edit Coupon"
        visible={editModalOpen}
        onCancel={hideEditModal}
        onOk={() => {
          editForm
            .validateFields()
            .then((values) => {
              editCoupon(values);
              editForm.resetFields();
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form form={editForm} layout="vertical" name="edit_coupon_form">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the coupon name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="discount"
            label="Discount (%)"
            rules={[{ required: true, message: "Please input the discount!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="expiry"
            label="Expiry Date"
            rules={[{ required: true, message: "Please input the expiry date!" }]}
          >
            <Input type="date" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CouponList;
